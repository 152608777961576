<template>
  <div class="page-wrap">
    <template v-if="vueType === 'Список'">
      <div class="page-content">
        <div class="container">
          <NavBtn :list="navList" />
          <ControlPanel>
            <template slot="left">
              <div class="vue-switch">
                <div class="vue-switch__label">ВИД:</div>
                <div class="vue-switch__btn">
                  <input class="vue-switch__field" type="radio" value="Список" v-model="vueType" />
                  <div class="vue-switch__cont">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 11a1 1 0 001 1h4a1 1 0 100-2H1a1 1 0 00-1 1zM1 0a1 1 0 000 2h16a1 1 0 100-2H1zM0 6a1 1 0 001 1h10a1 1 0 100-2H1a1 1 0 00-1 1z" fill="#0C76DA"/></svg>
                    <span>Список</span>
                  </div>
                </div>
                <div class="vue-switch__btn">
                  <input class="vue-switch__field" type="radio" value="Карта" v-model="vueType" />
                  <div class="vue-switch__cont">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.5 17.8c-.1 0-.3-.1-.3-.3V2.4c0-.2.1-.3.2-.3L6 .2l6 2.1L17.4.2h.1c.1 0 .3.1.3.3v15.1c0 .2-.1.3-.2.3L12 17.8l-6-2.1-5.4 2.1H.5zM12.8 3.7v12.1l3.4-1.1V2.4l-3.4 1.3zm-6 10.6l4.4 1.5V3.7L6.8 2.2v12.1zm-5-11v12.3l3.4-1.3V2.2L1.8 3.3z" fill="#000"/></svg>
                    <span>Карта</span>
                  </div>
                </div>
              </div>
            </template>
            <FilterContent slot="body" class="filter--content" />
          </ControlPanel>
          <template v-for="(item, index) in 2">
            <CardRequest :status="status" :person="person" :key="item.index">
              <template slot="footer-1">
                <BlockChat title="чат с исполнителем" />
              </template>
              <template slot="footer-2">
                <div class="block-line">
                  <router-link :to="{ name: 'ASSISTANCE_PROVIDE_COMPANY_RESPONSE', params: { responseId: index } }" class="block-line__link"
                    >Подробнее</router-link
                  >
                </div>
              </template>
            </CardRequest>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="page-map">
        <div class="page-map__content">
          <div class="vue-switch">
            <div class="vue-switch__label">ВИД:</div>
            <div class="vue-switch__btn">
              <input class="vue-switch__field" type="radio" value="Список" v-model="vueType" />
              <div class="vue-switch__cont">
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 11a1 1 0 001 1h4a1 1 0 100-2H1a1 1 0 00-1 1zM1 0a1 1 0 000 2h16a1 1 0 100-2H1zM0 6a1 1 0 001 1h10a1 1 0 100-2H1a1 1 0 00-1 1z" fill="#0C76DA"/></svg>
                <span>Список</span>
              </div>
            </div>
            <div class="vue-switch__btn">
              <input class="vue-switch__field" type="radio" value="Карта" v-model="vueType" />
              <div class="vue-switch__cont">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.5 17.8c-.1 0-.3-.1-.3-.3V2.4c0-.2.1-.3.2-.3L6 .2l6 2.1L17.4.2h.1c.1 0 .3.1.3.3v15.1c0 .2-.1.3-.2.3L12 17.8l-6-2.1-5.4 2.1H.5zM12.8 3.7v12.1l3.4-1.1V2.4l-3.4 1.3zm-6 10.6l4.4 1.5V3.7L6.8 2.2v12.1zm-5-11v12.3l3.4-1.3V2.2L1.8 3.3z" fill="#000"/></svg>
                <span>Карта</span>
              </div>
            </div>
          </div>
          <div class="map">
            <img src="@img/temp/map.jpg" alt="" />
          </div>
        </div>
        <div :class="['page-map__asside', { 'page-map__asside--shown': assideShown }]">
          <div class="page-map__collapse" @click="assideShown = !assideShown">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
          </div>
          <AssistanceAsside />
        </div>
        <!-- TODO добавить функционал -->
        <!-- <div class="page-map__footer">
          <v-dialog v-model="dialogSearchRadius">
            <template v-slot:activator="{ on, attrs }">
              <div class="page-map__link" v-on="on" v-bind="attrs">
                <div class="page-map__link-icon">
                  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
                </div>
                Радиус поиска заявок
              </div>
            </template>
            <v-card>
              <div class="btn-close" @click="dialogSearchRadius = false">
                <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
              </div>
              <ModalSearchRadius />
            </v-card>
          </v-dialog>
        </div> -->
      </div>
    </template>
  </div>
</template>

<script>
import NavBtn from '@component/Module/NavBtn';
import ControlPanel from '@component/Module/ControlPanel';
import CardRequest from '@component/Module/CardRequest';
import BlockChat from '@component/Module/BlockChat';
import AssistanceAsside from '@component/Part/AssistanceAsside';
import FilterContent from '@component/Module/FilterContent.vue';

export default {
  name: 'Responses',
  components: {
    NavBtn,
    ControlPanel,
    FilterContent,
    CardRequest,
    BlockChat,
    AssistanceAsside,
  },
  data() {
    return {
      navList: [
        {
          label: 'Заявки в работе',
          name: 'ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS',
          icon: require('@img/icons/app-new-provide.svg'),
        },
        {
          label: 'Отклики',
          name: 'ASSISTANCE_PROVIDE_COMPANY_RESPONSES',
          icon: require('@img/icons/app-responses-provide.svg'),
        },
        {
          label: 'Выполненные',
          name: 'ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATIONS',
          icon: require('@img/icons/app-completed-provide.svg'),
        },
      ],
      dialogSearchRadius: false,
      vueType: 'Список',
      status: {
        value: 'active',
        text: 'Активная',
      },
      person: {
        name: 'Василий Барка',
        date: '24 декабря 2020, 19:53',
        image: require('@img/temp/avatar-2.jpg'),
        role: 'Исполнитель',
      },
    };
  },
};
</script>

<style lang="scss" scoped>

// map-block
.page-map {
  .page-map__content {
    position: relative;
    .vue-switch {
      position: absolute;
      top: 62px;
      left: 50%;
      padding: 10px 20px 10px;
      transform: translateX(-50%);
      border-radius: 30px;
      background-color: #ffffff;
    }
    .map {
      width: 100%;
      height: 100vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .page-map__asside {
    position: fixed;
    right: 0;
    top: 0;
    width: 280px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    transform: translateX(100%);
    will-change: transform;
    transition: transform .2s ease-in-out;
    z-index: 20;
    .page-map__collapse {
      position: absolute;
      right: 100%;
      top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 46px;
      padding-left: 7px;
      border-radius: 30px 0 0 30px;
      background-color: var(--color-prime);
      transition: all .2s ease-in-out;
      cursor: pointer;
      svg {
        transform: rotateZ(90deg);
        transition: transform .2s ease-in-out;
        path {
          fill: #FFFFFF;
        }
      }
      &:hover {
        background-color: var(--color-prime-active);
      }
    }
    &--shown {
      transform: translateX(0);
      .page-map__collapse {
        svg {
          transform: rotateZ(90deg) rotateX(180deg);
        }
      }
    }
  }
  .page-map__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 14px;
    text-align: center;
    border-top: 1px solid rgba(#000000, 0.2);
    background-color: #FFFFFF;
    z-index: 10;
    .page-map__link {
      display: inline-flex;
      align-items: center;
      color: var(--color-prime);
      font-size: 15px;
      line-height: 1;
      transition: color .2s ease-in-out;
      cursor: pointer;
      .page-map__link-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        svg {
          path {
            fill: var(--color-prime);
          }
        }
      }
      &:hover {
        color: var(--color-prime-active);
      }
    }
  }
  @include respond-to('sm') {
    .page-map__content {
      .vue-switch {
        padding: 16px 30px 14px;
      }
    }
    .page-map__asside {
      width: 315px;
      .page-map__collapse {
        width: 30px;
        height: 50px;
      }
    }
    .page-map__footer {
      padding: 25px 16px;
      .page-map__link {
        font-size: 17px;
        .page-map__link-icon {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  @include respond-to('xl') {
    padding-right: 315px;
    .page-map__asside {
      transform: translateX(0);
      .page-map__collapse {
        display: none;
      }
    }
  }
}

// vue-switch
.vue-switch {
  display: flex;
  align-items: center;
  .vue-switch__label {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
  }
  .vue-switch__btn {
    position: relative;
    margin-left: 14px;
    .vue-switch__cont {
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      transition: all .2s ease-in-out;
      cursor: pointer;
      svg {
        width: 14px;
        height: auto;
        margin-right: 4px;
        path {
          fill: #000000;
          transition: all .2s ease-in-out;
        }
      }
    }
    .vue-switch__field {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      &:checked + .vue-switch__cont {
        color: var(--color-prime);
        svg {
          path {
            fill: var(--color-prime);
          }
        }
      }
    }
  }
  @include respond-to('sm') {
    .vue-switch__label {
      font-size: 13px;
    }
    .vue-switch__btn {
      margin-left: 20px;
      .vue-switch__cont {
        font-size: 13px;
        svg {
          width: 18px;
          margin-right: 8px;
        }
      }
    }
  }
}

</style>
