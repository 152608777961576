<template>
  <div :class="['block-chat', { 'block-chat--open': isOpen }]">
    <template v-if="isOpen">
      <div class="block-chat__collapse">
        <div class="block-chat__body">
          <div class="block-chat__list">
            <div class="block-chat__msg block-chat__msg--out">Здравствуйте</div>
            <div class="block-chat__msg block-chat__msg--in">Здравствуйте, Василий Барка</div>
          </div>
        </div>
        <div class="block-chat__footer">
          <textarea class="block-chat__field" placeholder="Введите текст сообщения"></textarea>
          <button type="button" class="btn btn--prime">Отправить</button>
        </div>
      </div>
    </template>
    <div class="block-chat__toggle">
      <div class="block-chat__toggle-btn" @click="isOpen = !isOpen">
        {{ triggerBtnText }} {{ title }}
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block-chat__toggle-ic'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockChat',
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    triggerBtnText() {
      return this.isOpen ? 'Свернуть' : 'Открыть';
    },
  },
};
</script>

<style scoped lang="scss">

// block-chat
.block-chat {
  .block-chat__toggle {
    padding: 14px 15px 17px;
    text-align: center;
    border-top: 1px solid rgba(163, 163, 163, 0.3);
    .block-chat__toggle-btn {
      display: inline-flex;
      align-items: center;
      color: var(--color-prime);
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
      transition: all .2s ease-in-out;
      @include respond-to('md') {
        font-size: 19px;
      }
      .block-chat__toggle-ic {
        display: block;
        margin-left: 10px;
        transition: all .2s ease-in-out;
        path {
          fill: var(--color-prime);
          transition: fill .2s ease-in-out;
        }
      }
      // &:before {
      //   content: "Открыть";
      //   margin-right: 5px;
      // }
      &:hover {
        color: var(--color-prime-active);
        .block-chat__toggle-ic {
          path {
            fill: var(--color-prime-active);
          }
        }
      }
    }
  }
  &--open {
    .block-chat__toggle {
      .block-chat__toggle-btn {
        // &:before {
        //   content: "Свернуть";
        // }
        .block-chat__toggle-ic {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .block-chat__collapse {
    background-color: #E8E9EB;
    .block-chat__body {
      padding: 0 15px;
    }
    .block-chat__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 15px 16px;
    }
    .block-chat__field {
      width: 100%;
      height: 56px;
      padding: 10px 19px;
      font-size: 15px;
      border-radius: 14px;
      border: 1px solid rgba(163, 163, 163, 0.3);
      background-color: #FFFFFF;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    .btn {
      flex-shrink: 0;
      width: auto;
      min-height: 56px;
      margin-left: 6px;
      @include respond-to('md') {
        width: 200px;
      }
    }
    .block-chat__list {
      display: flex;
      flex-direction: column;
      padding: 20px 0 0;
    }
    .block-chat__msg {
      min-width: 170px;
      margin-bottom: 6px;
      padding: 15px 19px;
      color: #000000;
      font-size: 15px;
      border-radius: 14px;
      &--in {
        align-self: flex-start;
        background-color: #FFFFFF;
      }
      &--out {
        align-self: flex-end;
        background-color: #E3F2FF;
      }
    }
  }
}
</style>
